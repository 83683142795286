const apiBaseURL = process.env.API_BASE_URL;

export const URL = {
    LOGIN_IN_URL: `${apiBaseURL}/api/admin/login`,
    LOG_OUT_URL: `${apiBaseURL}/api/admin/logout`,
    VERIFY_TOKEN_URL: `${apiBaseURL}/api/admin/verify-token`,
    ADD_PRODUCT_URL: `${apiBaseURL}/api/admin/product/add-product`,
    PRODUCT_LIST_URL: `${apiBaseURL}/api/admin/product/product-list`,
    ADD_MEMBER_URL: `${apiBaseURL}/api/admin/member/add-member`,
    MEMBER_LIST_URL: `${apiBaseURL}/api/admin/member/member-list`,
    MEMBER_LIST_FOR_ASSIGN_URL: `${apiBaseURL}/api/admin/member/member-list-for-assign`,
    ADD_DEALER_URL: `${apiBaseURL}/api/admin/add-dealer`,
    DEALER_LIST_URL: `${apiBaseURL}/api/admin/dealer-list`,
    DEALER_LIST_WITH_ASSIGNEE_URL: `${apiBaseURL}/api/admin/dealer-list-with-assignee`,
    UNASSIGNED_DEALER_LIST_URL: `${apiBaseURL}/api/admin/member/unassigned-shops`,
    ASSIGN_SHOP_OPERATION_URL: `${apiBaseURL}/api/admin/member/assign-shop-operation`,
    UPLOAD_IMAGE: `${apiBaseURL}/api/admin/upload-image`,
    AGENCIES_URL: `${apiBaseURL}/api/admin/agencies`,
    ORDER_DATA_EXCEL_URL: `${apiBaseURL}/api/admin/order/get-order-data-excel`,
    CLOSE_TAKING_ORDER: `${apiBaseURL}/api/admin/order/close-taking-order`,
    DEALER_LIST_WITH_PENDING_ORDER: `${apiBaseURL}/api/admin/pending-order-dealer-list`,
    ROUTE_SHEET_URL: `${apiBaseURL}/api/admin/order/get-order-route-excel`,
    TRACK_SHEET_URL: `${apiBaseURL}/api/admin/order/get-order-truck-excel`,
    UPLOAD_ORDER_EXCEL_URL: `${apiBaseURL}/api/admin/order/update-orders-from-excel`,
    ORDER_DATA_URL: `${apiBaseURL}/api/admin/order/get-order-data-display`
};